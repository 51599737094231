import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import {
  CardElement,
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import {
  createTransaction,
  createPaymentIntent,
} from "../../features/transactionSlice";
import { createPaymentIntentService } from "../../services/transactionServices";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useFetch from "../../hooks/useFetch";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const Payment = ({ postDetails, foodRequestDetails }) => {
  const dispatch = useDispatch();
  const customFetch = useFetch();
  const [paymentIntent, setPaymentIntent] = useState(null);

  //let paymentIntent = useSelector((state) => state.transaction.paymentIntent);

  const [clientSecret, setClientSecret] = useState("");

  useEffect(() => {
    getIntent();
    async function getIntent() {
      if (foodRequestDetails && postDetails) {
        let formData = new FormData();
        formData.append("postID", postDetails._id);
        formData.append("foodRequestID", foodRequestDetails._id);
        let paymentIntentResponse = await customFetch(
          createPaymentIntentService,
          formData
        );
        setPaymentIntent(paymentIntentResponse);
      }
    }
  }, [postDetails, foodRequestDetails]);

  useEffect(() => {
    if (
      paymentIntent &&
      paymentIntent.data &&
      paymentIntent.data.paymentIntent
    ) {
      setClientSecret(
        paymentIntent.data.paymentIntent.client_secret.toString()
      );
    }
  }, [paymentIntent]);

  const appearance = {
    theme: "stripe",
  };

  const options = {
    clientSecret: clientSecret,
  };

  return (
    <div className="pt-4">
      {clientSecret !== null && clientSecret != "" && (
        <Elements options={options} stripe={stripePromise}>
          <CheckOutForm
            postID={postDetails._id}
            foodRequestID={foodRequestDetails._id}
            paymentIntent={paymentIntent}
          />
        </Elements>
      )}
    </div>
  );
};

const CheckOutForm = ({ postID, foodRequestID, paymentIntent }) => {
  const dispatch = useDispatch();
  const customFetch = useFetch();

  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [clientSecret, setClientSecret] = useState("");

  //let paymentIntent = useSelector((state) => state.transaction.paymentIntent);

  useEffect(() => {
    if (
      paymentIntent &&
      paymentIntent.data &&
      paymentIntent.data.paymentIntent
    ) {
      setClientSecret(
        paymentIntent.data.paymentIntent.client_secret.toString()
      );
    }
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const result = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: "https://app-thechefe.onrender.com",
      },
      redirect: "if_required",
    });

    if (result.error) {
      setMessage(result.error.message);
    } else {
      if (result.paymentIntent.status === "succeeded") {
        setMessage("Payment succeeded!");
        //create bank account token
        let bankAccountToken = await stripe.createToken("bank_account", {
          country: "PT",
          currency: "eur",
          account_holder_name: "Jenny Rosen",
          account_holder_type: "individual",
          account_number: "PT89370400440532013002",
        });
        let formData = new FormData();
        formData.append("postID", postID);
        formData.append("foodRequestID", foodRequestID);
        formData.append("transactionID", result.paymentIntent.id);
        formData.append("transactionResponse", JSON.stringify(result));
        formData.append("bankAccountToken", JSON.stringify(bankAccountToken));
        dispatch(
          createTransaction({
            customFetch,
            formData,
          })
        );
      }
    }
  };

  return (
    <div className="pt-4">
      <form id="payment-form" onSubmit={handleSubmit}>
        <PaymentElement id="payment-element" />

        <button
          disabled={isLoading || !stripe || !elements}
          id="submit"
          className="btn btn-danger btn-block mt-3 w-100"
        >
          <span id="button-text">
            {isLoading ? (
              <div className="spinner" id="spinner"></div>
            ) : (
              "Pay now"
            )}
          </span>
        </button>
        {message && <div id="payment-message">{message}</div>}
      </form>
    </div>
  );
};

export default Payment;
