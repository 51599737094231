import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useFetch from "../../hooks/useFetch";
import { fetchPostService } from "../../services/postServices";
import { dp } from "../../assets";
import ReactTimeAgo from "react-time-ago";
import { Link } from "react-router-dom";
import Backdrop from "../Backdrop/Backdrop";
import Payment from "../Post/payment";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import TableBody from "@mui/material/TableBody";
import Typography from "@mui/material/Typography";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";

import Iconify from "../../components/iconify";
import Scrollbar from "../../components/scrollbar";

import TableNoData from "../../sections/user/table-no-data";
import UserTableRow from "../../sections/user/user-table-row";
import UserTableHead from "../../sections/user/user-table-head";
import TableEmptyRows from "../../sections/user/table-empty-rows";
import UserTableToolbar from "../../sections/user/user-table-toolbar";
import {
  emptyRows,
  applyFilter,
  getComparator,
} from "../../sections/user/utils";

import {
  getFoodRequestDoneByUser,
  getFoodRequestToUser,
} from "../../features/foodRequestSlice";

const FoodRequest = () => {
  const dispatch = useDispatch();
  const customFetch = useFetch();

  const [postDetails, setPostDetails] = React.useState();
  const [foodRequestDetails, setFoodRequestDetails] = React.useState();
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState("asc");

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState("name");

  const [filterName, setFilterName] = useState("");

  const [rowsPerPage, setRowsPerPage] = useState(5);

  let foodRequests = useSelector(
    (state) => state.foodRequest.allFoodRequestDoneByUser
  );

  const {
    user: { id, isGuest },
  } = useSelector((state) => state);

  useEffect(() => {
    if (!isGuest) {
      dispatch(getFoodRequestDoneByUser({ customFetch }));
      dispatch(getFoodRequestToUser({ customFetch }));
    }
  }, []);

  const handleSort = (event, id) => {
    const isAsc = orderBy === id && order === "asc";
    if (id !== "") {
      setOrder(isAsc ? "desc" : "asc");
      setOrderBy(id);
    }
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = foodRequests.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const dataFiltered = applyFilter({
    inputData: foodRequests,
    comparator: getComparator(order, orderBy),
    filterName,
  });

  const notFound = !dataFiltered.length && !!filterName;

  let foodRequestsToUser = useSelector(
    (state) => state.foodRequest.allFoodRequestToUser
  );

  const getPostFullDetails = async (id, foodRequest) => {
    const data = await customFetch(fetchPostService, id);
    setPostDetails(data.post);
    //setIsModalOpen(true);
    setFoodRequestDetails(foodRequest);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    console.log("foodRequests", foodRequests);
  }, []);

  const getPostDetails = (id, foodRequest) => {
    return (
      <>
        <button
          className="btn btn-success btn-sm btn-block"
          onClick={() => getPostFullDetails(id, foodRequest)}
        >
          {" "}
          Process
        </button>
      </>
    );
  };

  const onPaymentProcessDetails = async (requestId, postDetails) => {
    let paymentFoodRequestDetails = foodRequests.find((foodreq) => {
      return foodreq._id === requestId;
    });
    setFoodRequestDetails(paymentFoodRequestDetails);
    setPostDetails(paymentFoodRequestDetails.postID);
    setIsModalOpen(true);
  };

  return (
    <>
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4">
            My Requests - Track my food request status
          </Typography>
        </Stack>

        <Card>
          <UserTableToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
          />

          <Scrollbar>
            <TableContainer sx={{ overflow: "unset" }}>
              <Table sx={{ minWidth: 800 }}>
                <UserTableHead
                  order={order}
                  orderBy={orderBy}
                  rowCount={foodRequests.length}
                  numSelected={selected.length}
                  onRequestSort={handleSort}
                  onSelectAllClick={handleSelectAllClick}
                  headLabel={[
                    { id: "name", label: "Name" },
                    { id: "requestMessage", label: "Request Message" },
                    { id: "postID", label: "Go to post", align: "center" },
                    { id: "status", label: "Status" },
                    { id: "time", label: "Time" },
                    { id: "" },
                  ]}
                />
                <TableBody>
                  {dataFiltered
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => (
                      <UserTableRow
                        key={row._id}
                        name={row.postOwner.name}
                        status={row.requestStatus}
                        post={row.postID}
                        requestMessage={row.requestMessage}
                        time={row.createdAt}
                        avatarUrl={row.postOwner.profileImage}
                        selected={selected.indexOf(row.name) !== -1}
                        handleClick={(event) => handleClick(event, row)}
                        getPostDetails={() =>
                          getPostFullDetails(
                            row && row.postID && row.postID._id
                              ? row.postID._id
                              : row.postID,
                            row
                          )
                        }
                        isApproveVisible={false}
                        requestId={row._id}
                        onPaymentProcessHandler={onPaymentProcessDetails}
                      />
                    ))}

                  <TableEmptyRows
                    height={77}
                    emptyRows={emptyRows(
                      page,
                      rowsPerPage,
                      foodRequests.length
                    )}
                  />

                  {notFound && <TableNoData query={filterName} />}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            page={page}
            component="div"
            count={foodRequests.length}
            rowsPerPage={rowsPerPage}
            onPageChange={handleChangePage}
            rowsPerPageOptions={[10, 25]}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
      <Backdrop
        children={
          <>
            <Payment
              postDetails={postDetails}
              foodRequestDetails={foodRequestDetails}
            ></Payment>
          </>
        }
        show={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
        }}
      ></Backdrop>
    </>
  );
};

export default FoodRequest;
