import { lazy, Suspense } from "react";
import { Outlet, Navigate, useRoutes } from "react-router-dom";

import DashboardLayout from "../layouts/dashboard";
import ProtectedRoute from "./ProtectedRoute";

export const IndexPage = lazy(() => import("../pages/app"));
export const BlogPage = lazy(() => import("../pages/blog"));

export const ChefeFeedFoodMap = lazy(() => import("../pages/foodmap"));
export const UserPage = lazy(() => import("../pages/user"));
export const Transactions = lazy(
  () => import("../pages/Transactions/Transactions")
);
export const LoginPage = lazy(() => import("../pages/Auth/Auth"));
export const ProductsPage = lazy(() => import("../pages/products"));
export const Page404 = lazy(() => import("../pages/page-not-found"));
export const Feeds = lazy(() => import("../pages/Home/Home"));
export const CreatePostPage = lazy(() => import("../pages/createPost"));
export const ChatPage = lazy(() => import("../pages/chat"));
export const FAQSPage = lazy(() => import("../pages/faqs"));
export const ProfilePage = lazy(() => import("../pages/profile"));
export const MessengerPage = lazy(() => import("../pages/Messenger/Messenger"));
export const SinglePageNew = lazy(
  () => import("../pages/Singlepost/SinglePost")
);
export const MyRequests = lazy(
  () => import("../components/FoodRequest/FoodRequest")
);
export const ApproveRequests = lazy(
  () => import("../components/FoodRequest/Approve-request")
);

// ----------------------------------------------------------------------

export default function Router() {
  const authenticate = (Comp) => (
    <ProtectedRoute>
      <Comp />
    </ProtectedRoute>
  );

  const routes = useRoutes([
    {
      element: (
        <DashboardLayout>
          <Suspense>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      ),
      children: [
        { element: <IndexPage />, index: true },
        { path: "user", element: <UserPage /> },
        { path: "user/:id", element: <ProfilePage /> },
        { path: "products", element: <ProductsPage /> },
        { path: "blog", element: <BlogPage /> },
        { path: "foodmap", element: <ChefeFeedFoodMap /> },
        { path: "feed", element: <Feeds /> },
        { path: "createPost", element: <CreatePostPage /> },
        { path: "chat", element: <ChatPage /> },
        { path: "/chat/messenger", element: authenticate(MessengerPage) },
        { path: "myrequest", element: <MyRequests /> },
        { path: "approveRequest", element: authenticate(ApproveRequests) },
        { path: "transactions", element: authenticate(Transactions) },
        { path: "faqs", element: <FAQSPage /> },
        { path: "/post/:id", element: <SinglePageNew /> },
      ],
    },
    {
      path: "login",
      element: <LoginPage />,
    },
    {
      path: "404",
      element: <Page404 />,
    },
    {
      path: "*",
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
