import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { store } from "./app/store";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en.json";
import { Auth0Provider } from "@auth0/auth0-react";

import history from "../src/history";

TimeAgo.addDefaultLocale(en);
ReactDOM.render(
  <Auth0Provider
    domain={"chefefeed.eu.auth0.com"}
    clientId={"k1UjDcCiTktT0SapdXquHItdCn5RobZ7"}
    authorizationParams={{
      redirect_uri: window.location.origin,
    }}
  >
    <React.StrictMode>
      <Provider store={store}>
        <Router basename="/" history={history}>
          <App />
        </Router>
      </Provider>
    </React.StrictMode>
  </Auth0Provider>,
  document.getElementById("root")
);
